import {queryRedDotUrl} from "../requestUrl/index.js";
import {RED_DOT_TYPES} from "../../common/commonConst.js";
import mpegts from "mpegts.js";
/**
 * 获取小红点显示
 * @param {Object} vue vue对象
 * @param {Number} type 系统服务类型
 * type:
 *      "ent.inquiry": "企业服务管理询价",
 "ent.billpayment": "企业服务账单新付款",
 "ent.billrefund": "企业服务账单新退款",
 "tic.billpayment": "工单账单新付款",
 "tic.billrefund": "工单账单新退款",
 "soc.sensitive": "社区管理新敏感字图",
 *  */
function getShowRedDot(vue, type) {
    return vue.$fly
        .get(queryRedDotUrl, {
            type,
        })
        .then((res) => {
            if (res.code !== 0) {
                return false;
            }
            const typeMapping = {
                [RED_DOT_TYPES.ENT_INQUIRY]: "企业服务管理询价",
                [RED_DOT_TYPES.ENT_BILL_PAYMENT]: "企业服务账单新付款",
                [RED_DOT_TYPES.ENT_BILL_REFUND]: "企业服务账单新退款",
                [RED_DOT_TYPES.TIC_BILL_PAYMENT]: "工单账单新付款",
                [RED_DOT_TYPES.TIC_BILL_REFUND]: "工单账单新退款",
                [RED_DOT_TYPES.SOC_SENSITIVE]: "社区管理新敏感字图",
            };

            vue.$vc.emit("dot", "menu", {
                typeStr: typeMapping[type],
                type,
                isShow: res.data.isShow,
            });
            switch (type) {
                case RED_DOT_TYPES.ENT_INQUIRY:
                    vue.$vc.emit("dot", "enterpriseOrder", res.data.isShow);
                    break;
                case RED_DOT_TYPES.ENT_BILL_PAYMENT:
                    vue.$vc.emit("dot", "enterpriseBill", {
                        isShow: res.data.isShow,
                        index: 0,
                    });
                    break;
                case RED_DOT_TYPES.ENT_BILL_REFUND:
                    vue.$vc.emit("dot", "enterpriseBill", {
                        isShow: res.data.isShow,
                        index: 1,
                    });
                    break;
                case RED_DOT_TYPES.TIC_BILL_PAYMENT:
                    vue.$vc.emit("dot", "ticakeBillTabar", {
                        isShow: res.data.isShow,
                        index: 0,
                    });
                    break;
                case RED_DOT_TYPES.TIC_BILL_REFUND:
                    vue.$vc.emit("dot", "ticakeBillTabar", {
                        isShow: res.data.isShow,
                        index: 0,
                    });
                    break;
                case RED_DOT_TYPES.SOC_SENSITIVE:
                    vue.$vc.emit("dot", "communityPostTabar", {
                        isShow: res.data.isShow,
                        index: 1,
                    });
                    break;
            }
            return res.data.isShow;
        })
        .catch((err) => {
            return false;
        });
}

const {Events: PlayerEvents} = mpegts;

/**
 * 初始化流视频播放器
 * @param {Function} fail 初始化播放器失败处理
 * @param {String | HTMLElement} el 视频播放元素
 * @param {*} metaDataSource 播放源设置
 * @param {Object} playConfig 播放设置
 * @param {Function} loading 播放器加载中处理
 * @return {Object} 播放器对象
 * */
async function initStreamVideoPlayer(fail, el, metaDataSource, playConfig, loading) {
    const defaultSourceConfig = {
        type: 'flv',
        isLive: true,
        url: '',
    };
    for (let key in defaultSourceConfig) {
        if (!metaDataSource.hasOwnProperty(key)) {
            metaDataSource[key] = defaultSourceConfig[key];
        }
    }
    console.log(playConfig, '播放器配置');
    if (mpegts.getFeatureList().mseLivePlayback) {
        let videoElement;
        if (typeof el === 'string') {
            videoElement = document.getElementById(el);
        } else {
            videoElement = el;
        }
        const player = mpegts.createPlayer(metaDataSource, playConfig);
        player.attachMediaElement(videoElement);
        player.load();
        player.play();
        player.on(PlayerEvents.ERROR, async e => {
            if (fail) {
                fail(e)
            }
        })
        player.on(PlayerEvents.LOADING_COMPLETE, e => {
            if (loading) {
                loading(e)
            }
        })
        return player;
    }
}

export {getShowRedDot, initStreamVideoPlayer};
